<template>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <v-card>

        <!-- Toolbar -->
        <v-row no-gutters>
            <v-col class="d-flex pa-6 align-center justify-space-between">
                <h2 v-if="student !== null" class="d-flex align-center justify-space-between" >
                    <v-icon class="mr-2" large>account_circle</v-icon> 
                    <v-badge bordered :color="decidedColor" dot>{{ student.name }}</v-badge>
                </h2>
                <v-btn icon @click="dismissDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn> 
            </v-col>
        </v-row>
        <!-- Toolbar -->
        
        <!-- Content -->
        <v-row no-gutters class="pa-6">
            <v-col>
                <h3 class="pb-2"><v-icon>announcement</v-icon> Student Information</h3>
                <hr/>
                <!-- Inner content -->
                <div class="pt-4 pb-4 d-flex flex-column justify-space-between" style="height: 100%;"  v-if="student !== null">
                    <div class="d-flex justify-space-between">
                        <span>Name</span> <span>{{ student.name }}</span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span>Create Date</span> <span>{{ student.create_date.day }}/{{ student.create_date.month }}/{{ student.create_date.year }}</span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span>Guardian</span> <span>Richard Martin</span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span>Allergies</span> <span>Dust / Insect / Pollen</span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span>Emergency Contact</span> 
                        <span>
                            <v-select
                                :items="items"
                                solo
                                label="Choose Number"
                                style="width: 170px; "
                                dense
                                hide-details=""
                            />
                        </span>
                    </div>
                </div>
                <!-- Inner content -->
            </v-col>
        </v-row>
        <v-row no-gutters class="pa-6">
            <v-col>
                <div class="d-flex justify-space-between align-end">
                    <h3 class="pb-2"><v-icon>notes</v-icon> Notes</h3>
                    <v-btn class="ma-2" fab color="primary" @click="addNote()" x-small><v-icon>mdi-pencil</v-icon></v-btn>
                    <!-- <v-icon color="warning" @click="addNote()" style="cursor: pointer;">note_add</v-icon> -->
                </div>
                <hr/>
                <div class="d-flex flex-wrap" v-if="notes.length > 0">
                    <div class="notediv" v-for="(note, index) in notes" :key="index">
                        <p> {{ note }} </p>
                    </div>
                </div>
                <div v-else class="d-flex pa-6 justify-center flex-column align-center">
                    <div><v-icon x-large color="red">fa fa-times-circle</v-icon></div>
                    <div class="mt-3">No Notes Found</div>
                    <div><v-btn color="primary" x-small @click="addNote()">Add a Note</v-btn></div> 
                </div>
            </v-col>
        </v-row>
        <!-- Content -->
      </v-card>
      <writenote @close-fs-modal="dialog = false" ref="note"/>
    </v-dialog>
</template>

<script>
export default {
    name: 'Fullscreenmodal',
    components: { 
        Writenote: () => import('./Writenote'),
    },
    data: () => ({
        decidedColor: 'purple',
        dialog: false,
        student: null,
        notes: [],
        items: [`+919018386721`, '+919018386723' ]
    }),
    methods: {
        // Invoked from parent
        toggleDialog(data) {
            this.student = data;
            this.setDecidedColor(data);
            data.notes !== null && this.setNotes(data);
            this.dialog = !this.dialog;
        },

        // Clear notes and close modal 
        dismissDialog() {
            this.notes = [];
            this.dialog = false;
        },

        // Get Notes
        setNotes(data) {
            this.notes = data.notes.split(";");
        },  

        // Add Note
        addNote() {
            this.$refs.note.toggleDialog(this.student.id);
        },

        // 
        setDecidedColor(data) {
            if (data.attype === 'i') {
                return this.decidedColor = 'warning'
            } else if (data.attype === 'c') {
                return this.decidedColor = 'success'
            } else if (data.attype === 'u') {
                return this.decidedColor = 'primary'
            } else if (data.attype === null && data.endtime !== null) {
                return this.decidedColor = 'error'
            } else if (data.attype === null) {
                return this.decidedColor = '#E0E0E0'
            }
        }
    },
}
</script>

<style scoped>
    .notediv {
        padding: 9px;
        color: black;
        background-color:#f7dc77;
        margin: 8px;
        width: 30%;
        height: 180px;
        word-wrap: break-word;
        font-family: cursive;
        -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.2);
    }
</style>